<template>
  <div class="pagePadding">
    <span class="pageBtn finger btnReset" @click="back">返回</span>
    <div style="margin-top: 20px;">
      <Table :TotalQuantity="TotalQuantity" :productList="listColumns1" :productData="listData" border class="table" :total="total" :pages="pages" @change-page="changePage" :loading="loading" :isLoad="loading">
        <template slot-scope="" slot="do">
          <div><span class="finger color389">详情</span></div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'detailsTirdFactorySupplyList',
  components: {
    Table,
  },
  data() {
    return {
      loading: true,
      listColumns1: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          minWidth: 75,
          key: 'index',
        },
        {
          title: '日期',
          key: 'creatTime',
          align: 'center',
          width: 130,
        },
        {
          title: '单据类型',
          key: 'order_type',
          align: 'center',
          width: 150,
        },
        {
          title: '单据编号',
          key: 'order_number',
          align: 'center',
          width: 150,
        },
        {
          title: '采购数量',
          key: 'receipt_quantity',
          align: 'center',
          width: 130,
        },
        {
          title: '采购单价',
          key: 'receipt_unit_price',
          align: 'center',
          width: 130,
        },
        {
          title: '采购金额',
          key: 'receipt_amount',
          align: 'center',
          width: 130,
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          width: 130,
        },
        {
          title: '销售数量',
          key: 'delivery_quantity',
          align: 'center',
          width: 130,
        },
        {
          title: '销售单价',
          key: 'delivery_unit_price',
          align: 'center',
          width: 130,
        },
        {
          title: '销售金额',
          key: 'delivery_amount',
          align: 'center',
          width: 130,
        },
        {
          title: '利润',
          key: 'profit',
          align: 'center',
          width: 130,
        },
        {
          title: '操作',
          align: 'center',
          width: 150,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    fontSize: '14px',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetail(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      listData: [],
      total: 0,
      pages: {
        licence_code: '',
        create_time: '',
        page: 1,
        rows: 10,
      },
      TotalQuantity: [],
    }
  },
  created() {
    this.pages.licence_code = this.$route.query.licence_code
    this.pages.create_time = this.$route.query.create_time
    this.pages.product_name = this.$route.query.product_name
    this.queryList()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changePage(e) {
      this.pages.page = e
      this.queryList()
    },
    goDetail(item) {
      if (item.order_type == '采购入库单') {
        this.$router.push({
          path: '/detailsWarehouseEntryRecord',
          query: {
            warehouse_receipt_id: item.receipt_id,
          },
        })
      } else {
        this.$router.push({
          path: '/detailsWarehouseReceiptRecord',
          query: {
            id: item.delivery_id,
          },
        })
      }
    },
    queryList() {
      this.loading = true
      this.$http.get(this.$api.productSupplyThrid, this.pages, true).then(res => {
        this.TotalQuantity = []
        this.total = res.data.total
        this.listData = res.data.result
        this.TotalQuantity.push({ title: '销售总金额', total: '¥' + res.data.total_sale_amount })
        this.TotalQuantity.push({ title: '销售总数量', total: res.data.total_sale_quantity })
        this.TotalQuantity.push({ title: '采购总金额', total: '¥' + res.data.total_purchase_amount })
        this.TotalQuantity.push({ title: '采购总数量', total: res.data.total_purchase_quantity })
        this.loading = false
        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].creatTime = this.$moment(this.listData[i].create_time * 1000).format('YYYY-MM-DD')
        }
      })
    },
  },
}
</script>

<style></style>
